<script>
import categoryForm from "@/views/menu/category/categoryForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";

@Component({
  inject: {
    categoryService: "categoryService",
    fileUploadService: "fileUploadService",
    infoBoxService : "infoBoxService",

  }
})
export default class CategoryEdit extends mixins(categoryForm) {
  formId = "edit-category-form";
  infoBoxes = [];

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.categoryService.update(this.model.id, this.model);
      this.$router.push("/category/list");
      toastService.success(this.translations.success.category_update);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  get decoratedElements(){
    const decoratedElements = this.insertBefore(
      this.elements,
      {
        id: "idInfoBox",
        label: this.translations.labels.category_form_infobox,
        type: "select",
        items: this.infoboxList,
        clearable: true,
        required: false,
      },
      "localizations"
    );

    return this.insertBefore(
      decoratedElements,
      {
            id: "infoLabel",
            label: "Info Label",
            type: "text",
            rules: "max:12",
      },
      "localizations"
    );
  }

  get infoboxList() {
    return this.infoBoxes.filter(l => l.isEnabled == true).map((l) => {
        return {
          value: l.id,
          label: l.code,
        };
    });
  }

  getFormTitle() {
    return this.translations.pageTitles.categories_edit;
  }

  async afterCreate () {
    this.model = await this.categoryService.read(this.$route.params.id);
    this.infoBoxes = await this.infoBoxService.list(this.$route.params.id);

    this.isReady = true;
  }
}
</script>
